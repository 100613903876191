import React from 'react';
import { useEffect, useContext } from 'react';
import axios from 'axios';
import { Spinner, Button, Dropdown, Option, Tooltip, Label, ToggleButton, FluentProvider, Popover, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { SparkleFilled, ArrowSync12Filled, ContactCard16Regular, Settings16Regular, SignOutRegular, BookQuestionMarkRegular } from "@fluentui/react-icons";
import type { Theme } from "@fluentui/react-components";
import TabBar from './TabBar';
import InsightGenerator from './InsightGenerator';
import ClauseGenerator from './ClauseGenerator';
import AdminTab from './AdminTab'
import ChatTab from './ChatTab';
import LoginPage from './LoginPage';
import ChangePassword from './ChangePassword';
import AuthContext from '../AuthContext';
import callApi from '../apiUtils';

export interface ContentProps {
  lightTheme: Theme;
  darkTheme: Theme;
}

axios.defaults.withCredentials = true;

// Define your API base URLs
const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';

// Determine the base URL based on the environment
const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;

export const useAuth = () => useContext(AuthContext);

export default function App(props: ContentProps) {
  const [activeTab, setActiveTab] = React.useState('insightsAndSummary');
  const [error, setError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [generatedTextFromGenerate, setGeneratedTextFromGenerate] = React.useState<string>("");
  const [generatedTextFromClausify, setGeneratedTextFromClausify] = React.useState<string>("");

  //Insights Tab
  const [generatedProofread, setGeneratedProofread] = React.useState<string>("");
  const [generatedReview, setGeneratedReview] = React.useState<string>("");
  const [showInsights, setShowInsights] = React.useState(false);
  const [showReviews, setShowReviews] = React.useState(true);
  const [showGeneratedReview, setShowGeneratedReview] = React.useState(false);
  const [showGeneratedProofread, setShowGeneratedProofread] = React.useState(false);

  // Custom Playbook
  const [showPlaybook, setShowPlaybook] = React.useState(false);
  const [playbookSteps, setPlaybookSteps] = React.useState<string[]>([]);
  const [selectedPlaybook, setSelectedPlaybook] = React.useState<string>('');
  const [playbookName, setPlaybookName] = React.useState<string>('');
  const [newStep, setNewStep] = React.useState<string>('');
  const [playbookResults, setPlaybookResults] = React.useState(null);
  const [showGeneratedPlaybook, setShowGeneratedPlaybook] = React.useState(false);

  //Parties Dropdown
  const [parties, setParties] = React.useState<string[]>([]);
  const [selectedParty, setSelectedParty] = React.useState('');

  //Chat Tab
  const [chatLog, setChatLog] = React.useState([]);
  const [prompt, setPrompt] = React.useState<string>("");
  const [chatPrompt, setChatPrompt] = React.useState<string>("");

  //Clause Tab
  const [precedence, setPrecedence] = React.useState('');
  const [fileName, setFileName] = React.useState('');


  //Admin Tab data
  const [datesData, setDatesData] = React.useState([]);
  const [definitionsData, setDefinitionsData] = React.useState([]);
  const [referencesData, setReferencesData] = React.useState('');
  const [amountsData, setAmountsData] = React.useState([]);
  const [placeholdersData, setPlaceholdersData] = React.useState([]);
  const [showDefinitions, setShowDefinitions] = React.useState(false);
  const [showReferences, setShowReferences] = React.useState(false);
  const [showDates, setShowDates] = React.useState(false);
  const [showAmounts, setShowAmounts] = React.useState(false);
  const [showPlaceholders, setShowPlaceholders] = React.useState(false);
  const [showDisclaimer, setShowDisclaimer] = React.useState(true);


  //Login Data
  const [isAuthChecking, setIsAuthChecking] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [needPasswordChange, setNeedPasswordChange] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState('');

  // Define your API base URLs
  const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
  const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';
  
  // Determine the base URL based on the environment
  const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;


  const authContextValue = {
    isAuthenticated,
    setIsAuthenticated,
    needPasswordChange,
    setNeedPasswordChange,
    userEmail,
    setUserEmail,
  };
  

  useEffect(() => {
  }, [isAuthenticated]);

  useEffect(() => {
    onIdentifyParties();
  }, []); 

  const handlePartyChange = (event) => {
    setSelectedParty(event.target.value);
  };

  useEffect(() => {
    const verifyUserAuthentication = async () => {
      setIsAuthChecking(true);
      try {
        // Updated to POST request
        const response = await axios.post(`${API_BASE_URL}/auth-check`, {}, {
          withCredentials: true
        });
        if (response.data.isAuthenticated) {
          setIsAuthenticated(true);
          setUserEmail(response.data.email);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error verifying authentication:', error);
        setIsAuthenticated(false);
      } finally {
        setIsAuthChecking(false);
      }
    };
  
    verifyUserAuthentication();
  }, []);

  const handleLogout = async () => {
    try {
        await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true,  });
        console.log('Logged out successfully');
    } catch (error) {
        console.error('Logout error:', error);
    }
    setIsAuthenticated(false);
    setUserEmail('');
};


  const handleEmailClick = () => {
    const mailtoLink = "mailto:brian@draftdeep.com?subject=Support Ticket for DraftDeep&body=Please describe your issue or question:";
    window.location.href = mailtoLink; 
  };

  const settingsPopoverContent = (
    <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'left'
    }}>
      {process.env.REACT_APP_API_ENV === 'development' && (
      <div style={{ fontSize: '12px', marginBottom: '10px' }}>
        Front-end: development <br/>
        API Base URL: {API_BASE_URL}
      </div>
    )}
      <div style={{ fontSize: '12px' }}>{userEmail}</div>
        <Button onClick={handleEmailClick} icon={<BookQuestionMarkRegular />} appearance="subtle" style={{ marginTop: '10px', fontSize: '12px' }}>Get help</Button>
      <Button icon={<SignOutRegular />}  appearance="subtle" onClick={handleLogout} style={{ marginTop: '10px', fontSize: '12px' }}>Log Out</Button>
    </div>
  );



//App stuff
const onTabSelect = (_, data) => {
  setActiveTab(data.value);
};

const onIdentifyParties = async () => {
  setError("");
  try {
    const body = await Word.run(async (context) => {
      const body = context.document.body;
      body.load("text");
      await context.sync();
      return body.text.slice(0, 2000); 
    });

    const data = await callApi('identify-parties', 'POST', { documentText: body },setIsAuthenticated);
    if (data.parties.parties) {
      setParties(data.parties.parties);
    } else {
      setError("Error identifying parties");
      setParties([]); 
    }
  } catch (error) {
    console.error(error);
    setError("Failed to identify parties");
    setParties([]); 
  } finally {
  }
};
  
  const onInsert = async (textToInsert) => {
    try {
      await Word.run(async (context) => {
        const selection = context.document.getSelection();
        selection.insertText(textToInsert, "Start");
        await context.sync();
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const onCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  };

  const renderContent = () => {
    if (isAuthChecking) {
      return <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#F1F1F5'
      }}><Spinner labelPosition="below" label="Authorizing..."/></div>;
    }
    if (needPasswordChange) {
      return <ChangePassword />;
    }
    if (!isAuthenticated) {
      return <LoginPage />;
    }

  return (
    <>
    <AuthContext.Provider value={authContextValue}>
    <FluentProvider theme={props.lightTheme}>
      <div style={{
        background: '#F1F1F5',
        padding: '5px 0 0 0',
        position: 'fixed', // or 'sticky'
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden', // Prevents scrolling outside the inner container
      }}>
          <TabBar activeTab={activeTab} onTabSelect={onTabSelect} />

      <div style={{ 
        borderRadius: '16px 16px 0px 0px', 
        background: '#FFFFFF', 
        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
        minHeight: 'calc(100vh - 42px)',
        maxHeight: 'calc(100vh - 42px)', 
        overflow: 'auto', 
        scrollbarWidth: 'none', /* for Firefox */
        msOverflowStyle: 'none',  /* for Internet Explorer and Edge */
  }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 12px 0px 12px', width: '100%', boxSizing: 'border-box' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}>
                <Label style={{ color:"#242424" }} size="small">I am representing:</Label>
                <div>
                  <select
                    id="party-select"
                    value={selectedParty}
                    onChange={handlePartyChange}
                    style={{ color: "#242424", background: '#F5F5F5', fontSize: '12px', padding: '4px', borderRadius: '4px', border: 'none' }}
                  >
                    <option value="">Select party</option>
                    {parties.map((party, index) => (
                      <option key={index} value={party}>
                        {party}
                      </option>
                    ))}
                  </select>
                  <Tooltip content="Refresh parties" relationship="label">
                    <Button onClick={onIdentifyParties} size="small" icon={<ArrowSync12Filled />} appearance="transparent"></Button>
                  </Tooltip>
                </div>
              </div>
        
          <div>
          <Popover>
            <PopoverTrigger>
              <Button icon={<Settings16Regular />} size="small" appearance="transparent" title="Settings" />
            </PopoverTrigger>

            <PopoverSurface tabIndex={-1} style={{ borderRadius: '12px' }}>
              {settingsPopoverContent}
            </PopoverSurface>
          </Popover>

          </div>
        </div>
        


    {activeTab == 'chatTab' && (
      <>
      <ChatTab
        error={error}
        setError={setError}
        chatPrompt={chatPrompt}
        setChatPrompt={setChatPrompt}
        chatLog={chatLog}
        setChatLog={setChatLog}
        loading={loading}
        setLoading={setLoading}
        selectedParty={selectedParty}
      />
      </>
    )}

    {activeTab === 'draftAndClauses' && (
      <>
      <ClauseGenerator
          prompt={prompt}
          setPrompt={setPrompt}
          generatedTextFromGenerate={generatedTextFromGenerate}
          setGeneratedTextFromGenerate={setGeneratedTextFromGenerate}
          generatedTextFromClausify={generatedTextFromClausify}
          setGeneratedTextFromClausify={setGeneratedTextFromClausify}
          onInsert={onInsert}
          onCopy={onCopy}
          error={error}
          setError={setError}
          loading={loading}
          setLoading={setLoading}
          precedence={precedence}
          setPrecedence={setPrecedence}
          fileName={fileName}
          setFileName={setFileName}
          selectedParty={selectedParty}
        />
      </>
    )}

    {activeTab === 'insightsAndSummary' && (
        <>
        <InsightGenerator
          showInsights={showInsights}
          setShowInsights={setShowInsights}
          showReviews={showReviews}
          setShowReviews={setShowReviews}
          error={error}
          setError={setError}
          loading={loading}
          setLoading={setLoading}
          selectedParty={selectedParty}
          generatedReview={generatedReview}
          setGeneratedReview={setGeneratedReview}
          generatedProofread={generatedProofread}
          setGeneratedProofread={setGeneratedProofread}
          showGeneratedReview={showGeneratedReview}
          setShowGeneratedReview={setShowGeneratedReview}
          showGeneratedProofread={showGeneratedProofread}
          setShowGeneratedProofread={setShowGeneratedProofread}
          // Custom Playbook Props
          showPlaybook={showPlaybook}
          setShowPlaybook={setShowPlaybook}
          playbookSteps={playbookSteps}
          setPlaybookSteps={setPlaybookSteps}
          selectedPlaybook={selectedPlaybook}
          setSelectedPlaybook={setSelectedPlaybook}
          playbookName={playbookName}
          setPlaybookName={setPlaybookName}
          newStep={newStep}
          setNewStep={setNewStep}
          playbookResults={playbookResults}
          setPlaybookResults={setPlaybookResults}
          showGeneratedPlaybook={showGeneratedPlaybook}
          setShowGeneratedPlaybook={setShowGeneratedPlaybook}
        />

        </>
    )}

    {activeTab == 'adminTab' && (
      <>
        <AdminTab 
        showDisclaimer={showDisclaimer}
        setShowDisclaimer={setShowDisclaimer}
        referencesData={referencesData}
        setReferencesData={setReferencesData}
        datesData={datesData}
        setDatesData={setDatesData}
        setDefinitionsData={setDefinitionsData}
        definitionsData={definitionsData}
        amountsData={amountsData}
        setAmountsData={setAmountsData}
        placeholdersData={placeholdersData}
        setPlaceholdersData={setPlaceholdersData}
        showDefinitions={showDefinitions}
        setShowDefinitions={setShowDefinitions}
        showReferences={showReferences}
        setShowReferences={setShowReferences}
        showDates={showDates}
        setShowDates={setShowDates}
        showAmounts={showAmounts}
        setShowAmounts={setShowAmounts}
        // showPlaceholders={showPlaceholders}
        // setShowPlaceholders={setShowPlaceholders}
        error={error}
        setError={setError}
        loading={loading}
        setLoading={setLoading}
        />
      </>
    )}
      </div>
      </div>
    </FluentProvider>
    </AuthContext.Provider>
      </>
  );
};

return (
  <AuthContext.Provider value={authContextValue}>
    <FluentProvider theme={props.lightTheme}>
      {renderContent()}
    </FluentProvider>
  </AuthContext.Provider>
);
}
