import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { Button, Input, Link, Checkbox, MessageBar, MessageBarBody, MessageBarTitle, Spinner } from "@fluentui/react-components";
import { CheckmarkRegular } from "@fluentui/react-icons";


axios.defaults.withCredentials = true;

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDraftDeep, setShowDraftDeep] = useState(true);

    const { setIsAuthenticated, setNeedPasswordChange, setUserEmail } = useAuth();

      // Define your API base URLs
      const API_BASE_URL_DEVELOPMENT = 'https://thefuturenow.io/api';
      const API_BASE_URL_PRODUCTION = 'https://app.draftdeep.com/api';

      // Determine the base URL based on the environment
      const API_BASE_URL = process.env.REACT_APP_API_ENV === 'production' ? API_BASE_URL_PRODUCTION : API_BASE_URL_DEVELOPMENT;
  
    useEffect(() => {
      const getStartedFlag = localStorage.getItem('GetStarted');
      setShowDraftDeep(getStartedFlag !== 'true'); // Show DraftDeep if 'GetStarted' is true    
    
      const rememberedEmail = localStorage.getItem('rememberEmail');
      if (rememberedEmail) {
        setEmail(rememberedEmail);
        setRememberMe(true);
      }
      // Check for an existing deviceId, or create a new one
      if (!localStorage.getItem('deviceId')) {
        const newDeviceId = 'device_' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('deviceId', newDeviceId);
    }
    }, []);
      
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setLoginError('');
      
        const deviceId = localStorage.getItem('deviceId');
      
        try {
        const response = await axios.post(`${API_BASE_URL}/login`, { email, password, deviceId },  { withCredentials: true });
      
          setUserEmail(email);
          setIsAuthenticated(true);
      
          if (response.data.changePassword) {
            setNeedPasswordChange(true);
          } else {
            setNeedPasswordChange(false);
          }
      
          if (rememberMe) {
            localStorage.setItem('rememberEmail', email);
          } else {
            localStorage.removeItem('rememberEmail');
          }
        } catch (error) {
          setLoginError(error.response?.data?.message || 'Login failed. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };      
  
    const handleRememberMeChange = (_, data) => {
      setRememberMe(data.checked);
    };  

    const LoadingOverlay = () => (
      <div style={{
        position: 'fixed', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        backgroundColor: 'rgba(0, 0, 0, 0.5)', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        margin: 'auto',
        zIndex: 1000
      }}>
          <Spinner appearance="inverted" size="medium" style={{ margin: 'auto' }} />
      </div>
    );

    const handleGetStartedClick = () => {
      localStorage.setItem('GetStarted', 'false');
      setShowDraftDeep(false);
    };

    if (showDraftDeep) {
      return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100vh', 
            backgroundColor: '#EDEBE9',
            textAlign: 'center',
            padding: '20px'
        }}>
          {isLoading && <LoadingOverlay />}
          <img src='https://storage.googleapis.com/draftdeep/assets/logo.svg' alt="Logo" style={{ width: '50px', marginBottom: '15px', color: '#6E738B' }} />
          <h1>DraftDeep</h1>
          <p>DraftDeep is an AI drafting copilot built for busy legal professionals looking to close more deals faster.</p>
          <div style={{ textAlign: 'left' }}> {/* Adjust alignment as needed */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <CheckmarkRegular style={{ height: '12px', width: '12px', color: '#5851D8' }} aria-hidden="true" />
              <span style={{ marginLeft: '10px' }}>Detect unfavorable terms</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <CheckmarkRegular style={{ height: '12px', width: '12px', color: '#5851D8' }} aria-hidden="true" />
              <span style={{ marginLeft: '10px' }}>Propose points to negotiate</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <CheckmarkRegular style={{ height: '12px', width: '12px', color: '#5851D8' }} aria-hidden="true" />
              <span style={{ marginLeft: '10px' }}>Suggest missing clauses or definitions</span>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <CheckmarkRegular style={{ height: '12px', width: '12px', color: '#5851D8' }} aria-hidden="true" />
              <span style={{ marginLeft: '10px' }}>Apply all comments / redlines instantly</span>
            </div>
          </div>
          <Button appearance="primary" onClick={handleGetStartedClick} style={{ marginTop: '20px'}}>Get Started</Button>
        </div>
      );
    }

  return (
    <div style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        backgroundColor: '#EDEBE9', 
        height: '100vh' 
    }}>
        {isLoading && <LoadingOverlay />}
  <div style={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center',
        justifyContent: 'center',
        width: '50%',
        margin: 'auto'
    }}>
        <div style={{        
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center',
        justifyContent: 'center',
        width: '100%'}}>
          <img src='https://storage.googleapis.com/draftdeep/assets/logo.svg' alt="Logo" style={{ width: '50px', marginBottom: '15px', color: '#6E738B', opacity: '0.8' }} />
            <h2>Login</h2>
            <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', gap: '10px', justifyContent: 'center'
            }}>
            <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                style= {{ width: '100%', maxWidth: '350px' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                    style= {{ width: '100%', maxWidth: '350px'}}
                />
                <Checkbox
                    label="Remember Me?"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                    style={{ marginTop: '5px'}}
                />
            </div>
            <Button appearance="primary" type="submit" style= {{ width: '100%', maxWidth: '350px'}}>Login</Button>
            <Link href="mailto:brian@draftdeep.com?subject=Password Reset Request&body=Please enter your email address associated with your account and any additional details here:" style={{ color: '#464646', margin: 'auto'}}>Forgot your password?</Link>
            </form>
            {loginError && (
                <MessageBar key={loginError} intent="error" style={{marginTop: '10px'}}>
                <MessageBarBody>
                    <MessageBarTitle>Error</MessageBarTitle>
                    {loginError}
                </MessageBarBody>
                </MessageBar>
            )}
        </div>
    </div>

      <div style={{ borderTop: '1px solid #CAC5C0', width: '70%', maxWidth: '350px', paddingBottom: '20px', margin: 'auto' }} />

      <div style={{ textAlign: 'center', padding: '30px 0px' }}>
        No account? <Link href="https://draftdeep.com/app-join-waitlist" >Join our waitlist</Link> {/* Join Waitlist link */}
      </div>
    </div>
  );
};

export default LoginPage;