import React, { useState, useContext, useEffect } from 'react';
import { Button, MessageBar, MessageBarBody,  MessageBarActions, MessageBarTitle, Accordion, AccordionItem, AccordionHeader, AccordionPanel, Spinner, SplitButton, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover, Field, ProgressBar, Input, Dropdown, Option } from "@fluentui/react-components";
import type { MenuButtonProps } from "@fluentui/react-components";
import { ArrowForward16Filled, Search16Regular, DismissRegular, ChevronUp16Filled, ChevronDown16Filled, CommentAdd16Regular, SparkleFilled } from "@fluentui/react-icons";
import { AcademicCapIcon, BoltIcon, ExclamationCircleIcon, QuestionMarkCircleIcon, PencilIcon, ChevronRightIcon, ChevronDownIcon, PuzzlePieceIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/solid'; 
import Container from "./Container";
import callApi from '../apiUtils';
import AuthContext, { useAuth } from '../AuthContext';
import { diffWords } from 'diff';
import "../styles.css"

const InsightGenerator = ({ generatedProofread, setGeneratedProofread, error, setError, setLoading, loading, selectedParty, showReviews, setShowReviews, showInsights, setShowInsights, generatedReview, setGeneratedReview, showGeneratedReview, setShowGeneratedReview, showGeneratedProofread, setShowGeneratedProofread, playbookSteps, setPlaybookSteps, selectedPlaybook, showPlaybook, setShowPlaybook, setSelectedPlaybook, playbookName, setPlaybookName, newStep, setNewStep, playbookResults, setPlaybookResults, showGeneratedPlaybook, setShowGeneratedPlaybook,}) => {

  const { setIsAuthenticated, userEmail } = useContext(AuthContext);
  const [reviewContext, setReviewContext] = useState('');
  const [proofreadContext, setProofreadContext] = useState('');
  const [scope, setScope] = useState('Whole Document');
  const [showAddPlaybook, setShowAddPlaybook] = useState(false);
  const [isEditingPlaybook, setIsEditingPlaybook] = useState(false);

   // Log when generatedProofread changes
   useEffect(() => {
    console.log('generatedProofread changed:', generatedProofread);
}, [generatedProofread]);

    // Log when generatedReview changes
    useEffect(() => {
      console.log('generatedReview changed:', generatedReview);
  }, [generatedReview]);

  // Call fetchPlaybooks when the component mounts
  useEffect(() => {
    fetchPlaybooks(userEmail, setIsAuthenticated);
  }, [userEmail, setIsAuthenticated]);
  

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowGeneratedProofread(false);
        setShowGeneratedReview(false);
        setShowGeneratedPlaybook(false);
      }
    };

    // Add event listener
    window.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []); // Empty dependency array means this effect runs only once after initial render

   // Function to handle showing the Add Playbook section
   const handleShowAddPlaybook = () => {
    setPlaybookName('');
    setPlaybookSteps([]);
    setShowAddPlaybook(true);
  };

  // Add Step function ensuring newStep is added as a string
  const addStep = () => {
    if (newStep.trim() !== '') {
      setPlaybookSteps([...playbookSteps, newStep]);
      setNewStep('');
    }
  };
  
  const removeStep = (index) => {
    const updatedSteps = playbookSteps.filter((_, i) => i !== index);
    setPlaybookSteps(updatedSteps);
  };
  

  const fetchPlaybooks = async (userEmail, setIsAuthenticated) => {
    try {
      // Fetch user_id based on userEmail
      const response = await callApi('fetch-playbooks', 'POST', { userEmail }, setIsAuthenticated);
      
      if (response.success) {
        setPlaybookSteps(response.playbooks);
      } else {
        throw new Error(response.error || 'Failed to fetch playbooks');
      }
    } catch (error) {
      console.error('Error fetching playbooks:', error);
      setError('Error fetching playbooks');
    }
  };
  
  useEffect(() => {
    if (playbookSteps.length > 0) {
      setSelectedPlaybook(playbookSteps[0].name);
    }
  }, [playbookSteps]);
  
  const savePlaybook = async (userEmail, setIsAuthenticated) => {
    if (!playbookName.trim()) {
      setError('Playbook name cannot be empty');
      return;
    }

    try {
      const endpoint = isEditingPlaybook ? 'update-playbook' : 'save-playbook'; // Determine endpoint
      const response = await callApi(endpoint, 'POST', { userEmail, name: playbookName, steps: playbookSteps }, setIsAuthenticated);
      if (response.success) {
        setPlaybookName('');
        setNewStep('');
        setPlaybookSteps([]);
        fetchPlaybooks(userEmail, setIsAuthenticated);
        setShowAddPlaybook(false);
        setError('');
        setIsEditingPlaybook(false); // Reset editing state
      } else {
        setError('Failed to save playbook');
      }
    } catch (error) {
      console.error('Error saving playbook:', error);
      setError('Error saving playbook');
    }
  };

  const deletePlaybook = async (playbookName) => {
    try {
      const response = await callApi('delete-playbook', 'POST', { userEmail, name: playbookName }, setIsAuthenticated);
      if (response.success) {
        fetchPlaybooks(userEmail, setIsAuthenticated);
        setSelectedPlaybook('');
        setPlaybookSteps([]);
      } else {
        setError('Failed to delete playbook');
      }
    } catch (error) {
      console.error('Error deleting playbook:', error);
      setError('Error deleting playbook');
    }
  };

  // Update playbook edit function
  const editPlaybook = (playbookName) => {
    const playbookToEdit = playbookSteps.find(playbook => playbook.name === playbookName);
    if (playbookToEdit) {
      setPlaybookName(playbookToEdit.name);
      setPlaybookSteps(playbookToEdit.steps);
      setSelectedPlaybook(playbookToEdit.name);
      setShowAddPlaybook(true);
      setIsEditingPlaybook(true); // Set editing state to true
    }
  };

  
  const runPlaybook = async (userEmail, setIsAuthenticated) => {
    try {
      setLoading(true);
      
      // Get the document text
      let documentText = "";
      await Word.run(async (context) => {
        const body = context.document.body;
        body.load("text");
        await context.sync();
        documentText = body.text;
      });
  
      if (!documentText.trim()) {
        setError('The document is empty.');
        setLoading(false);
        return;
      }
  
      if (!userEmail || !selectedPlaybook) {
        setError('User email or playbook name is missing.');
        setLoading(false);
        return;
      }
  
      const response = await callApi('run-playbook', 'POST', { 
        playbookName: selectedPlaybook, 
        userEmail, 
        documentText 
      }, setIsAuthenticated);
  
      if (response.success) {
        setPlaybookResults(response.results);
        setShowGeneratedPlaybook(true);
      } else {
        setError('Failed to run playbook');
      }
    } catch (error) {
      console.error('Error running playbook:', error);
      setError('Error running playbook');
    } finally {
      setLoading(false);
    }
  };

  const iconDetails = (type) => {
    switch (type) {
      case "Points to Negotiate":
        return { icon: AcademicCapIcon, color: "#596CD3" }; // Example color and icon
      case "Aggressive Terms":
        return { icon: BoltIcon, color: "#08815A" };
      case "Unusual Terms":
        return { icon: ExclamationCircleIcon, color: "#B95609" };
      case "Missing Terms":
        return { icon: QuestionMarkCircleIcon, color: "#CB3D64" };
      case "Proofreading Mistakes":
        return { icon: PencilIcon, color: "#A54FB4" };
      default:
        return { icon: PencilIcon, color: "#0779B3" }; // Default icon
    }
  };

  const checkContext = async (itemsJson, updateStateFn) => {
    let items = parseJSON(itemsJson);
    if (!items) {
      console.error("Failed to parse items for context checking.");
      return;
    }
  
    const updatedItems = await Promise.all(items.map(async (item) => {
      try {
        await Word.run(async (context) => {
          const searchResults = context.document.body.search(cleanContext(item.context), { matchWholeWord: false });
          searchResults.load('items');
          await context.sync();
  
          item.contextFound = searchResults.items.length > 0;
        });
      } catch (error) {
        console.error("Error checking context for item:", item.context, error);
        item.error = "Failed to check context"; // Optionally add an error message to the item
      }
      return item;
    }));
  
    updateStateFn(JSON.stringify(updatedItems)); // Update state with all items, regardless of individual failures
  };

  const renderDifferences = (oldText, newText) => {
    const diffResult = diffWords(oldText, newText);
    return diffResult.map((part, index) => {
      const style = {
        color: part.added ? 'green' : part.removed ? '#D13438' : '#404040',
        textDecoration: part.removed ? 'line-through' : 'none',
      };
      return <span key={index} style={style}>{part.value}</span>;
    });
  };

    const stripJSONHeaderFooter = (text) => {
        return text.replace(/^```json|```$/g, '');
      };
      
      const parseJSON = (jsonString) => {
        try {
          const strippedString = stripJSONHeaderFooter(jsonString);
          if (/^[\[\{]/.test(strippedString) && /[\]\}]$/.test(strippedString)) {
            return JSON.parse(strippedString);
          } else {
            console.error("Not a valid JSON string:", strippedString);
            return null;
          }
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return null;
        }
      };

const cleanContext = (context) => {
    return context.replace(/^[\.\s]+|[\.\s]+$/g, ''); // Removes leading/trailing dots and spaces
  };

// Updated function signature to include contentType
const applyChanges = async (context, fixedContext, comment, contentType) => {
  try {
    const cleanedContext = cleanContext(context); 
    await Word.run(async (wordContext) => {
      const document = wordContext.document;
      // Load the current change tracking mode
      document.load("changeTrackingMode");
      await wordContext.sync();

      // Store the original change tracking mode
      const originalChangeTrackingMode = document.changeTrackingMode;

      // Ensure change tracking is enabled if it's currently off
      if (originalChangeTrackingMode === Word.ChangeTrackingMode.off) {
        document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
        await wordContext.sync();
      }

      // Now proceed with the original operations
      const range = document.body.search(cleanedContext);
      range.load("text");
      await wordContext.sync();

      if (range.items.length > 0) {
        const rangeToChange = range.items[0];

        if (fixedContext && comment) {
          // Directly replace the text with fixed_context and insert a comment
          rangeToChange.insertText(fixedContext, "Replace");
          rangeToChange.insertComment(comment);
        } else if (fixedContext && !comment) {
          // Only replace the text
          rangeToChange.insertText(fixedContext, "Replace");
        } else if (!fixedContext && comment) {
          // Only add a comment
          rangeToChange.insertComment(comment);
        }
        
        rangeToChange.select();
        await wordContext.sync();

        // Decide which state to update based on contentType
        const updateState = contentType === 'review' ? setGeneratedReview : setGeneratedProofread;
        updateState((prev) => {
          const parsedItems = parseJSON(prev); 
          const updatedItems = parsedItems.map((item) => {
            if (item.context === context) {
              return { ...item, dismissed: true };
            } else {
              return item;
            }
          });
          return JSON.stringify(updatedItems);
        });
      } else {
        console.error('Context not found for comment:', comment);
      }

      // Restore the original change tracking mode if it was off
      if (originalChangeTrackingMode === Word.ChangeTrackingMode.off) {
        document.changeTrackingMode = Word.ChangeTrackingMode.off;
        await wordContext.sync();
      }
    });     
  } catch (error) {
    console.error("Error applying changes:", error);
    setError("Failed to apply changes");
  }
};

const navigateToContext = async (context) => {
  try {
    const cleanedContext = cleanContext(context); 

    await Word.run(async (wordContext) => {
      const searchResults = wordContext.document.body.search(cleanedContext, { matchWholeWord: false });
      searchResults.load('items');

      await wordContext.sync();

      if (searchResults.items.length > 0) {
        searchResults.items[0].select();
        await wordContext.sync();
      } else {
        console.log("No matches found"); 
      }
    });
  } catch (error) {
    console.error("Error navigating to context:", error);
    setError("Failed to navigate to context");
  }
};    

  const dismissInsight = async (context) => {
    try {
      setGeneratedProofread((prev) => {
        const parsedInsights = parseJSON(prev); 
        const updatedInsights = parsedInsights.map((item) => {
          if (item.context === context) {
              return { ...item, dismissed: !item.dismissed };
          } else {
              return item;
          }
        });
        return JSON.stringify(updatedInsights);
      });
    } catch (error) {
      console.error("Error dismissing insight:", error);
      setError("Failed to dismiss insight");
    }
  };

  const dismissReview = async (context) => {
    try {
      setGeneratedReview((prev) => {
        const parsedReviews = parseJSON(prev);
        if (!parsedReviews) {
          throw new Error("Failed to parse reviews");
        }
        const updatedReviews = parsedReviews.map((item) => {
          if (item.context === context) {
            return { ...item, dismissed: !item.dismissed };
          } else {
            return item;
          }
        });
        return JSON.stringify(updatedReviews);
      });
    } catch (error) {
      console.error("Error dismissing review:", error);
      setError("Failed to dismiss review"); 
    }
  };  

  const toggleShowGeneratedReview = () => {
    setShowGeneratedReview(!showGeneratedReview);
  };

  const onReview = async () => {
    setError("");
    setLoading(true);
  
    try {
      const text = await Word.run(async (context) => {
        let selectedText = "";
        if (scope === "Selected Section") {
          const selection = context.document.getSelection();
          selection.load("text");
          await context.sync();
          selectedText = selection.text;
        } else {
          const body = context.document.body;
          body.load("text");
          await context.sync();
          selectedText = body.text;
        }

        // Check if there's no text selected when the scope is "Selected Section"
        if (scope === "Selected Section" && !selectedText.trim()) {
          throw new Error("Please select a section of the document.");
        }

        return selectedText;
      });
  
      const data = await callApi('generate-review', 'POST', { documentText: text, reviewContext: reviewContext, party: selectedParty }, setIsAuthenticated);

      if (data.success) {
        const reviewsWithInitialContextFound = data.review.map(i => ({ ...i, dismissed: false, contextFound: false }));
        setGeneratedReview(JSON.stringify(reviewsWithInitialContextFound));
        checkContext(JSON.stringify(reviewsWithInitialContextFound), setGeneratedReview);
      } else {
        setError("Error generating review");
      }

    } catch (error) {
      setError(error.message || "Failed to generate review");
    } finally {
      setLoading(false);
    }
};
  

  const getPriorityStyle = (priority) => {
    switch (priority.toLowerCase()) {
      case 'critical':
        return { color: '#CE2C2C', fontSize: '12px', display: 'inline-block' }; // Pastel Red with smaller font
      case 'moderate':
        return { color: '#D16900', fontSize: '12px', display: 'inline-block' }; // Pastel Orange with smaller font
      case 'low':
        return { color: '#FBC02D', fontSize: '12px', display: 'inline-block' }; // Pastel Yellow with smaller font
      default:
        return {}; // Default style if needed
    }
  };  

  const renderReviewContent = () => {
    if (generatedReview) {
      const parsedData = parseJSON(generatedReview);
  
      if (!parsedData) return <Container><p>Error displaying reviews, please try again.</p></Container>;
  
      return (
        <Container>
          <Accordion multiple collapsible>
          {parsedData.sort((a, _) => (a.dismissed ? 1 : -1)).map((review, index) => {

          const primaryActionButtonProps = {
            onClick: () => applyChanges(review.context, review.fixed_context, review.comment, 'review'),
            style: { 
              fontSize: '12px', 
            },
          };

          return (
            <AccordionItem 
              key={index} 
              value={String(index)}
              className={`InsightAccordion ${review.dismissed ? 'dismissed' : ''}`}> 
                <AccordionHeader expandIconPosition="end" style={{ overflow: "hidden" }}>
                  <div style={{ display: 'flex', flexDirection: 'column', padding: '16px 10px 16px 10px' }}>
                    <div style={getPriorityStyle(review.priority)}><b>{review.priority}</b></div>
                    <div style={{ fontSize: '12px', color: '#3c3c3c', fontWeight: 'bold' }}>{review.title}</div>
                  </div>
                </AccordionHeader>
                <AccordionPanel style={{ color: "#3C3C3C", display: 'flex', fontSize: '12px', padding:'0px 20px 16px 20px', flexDirection: 'column', gap:'12px', margin: '0px' }}>
                  <div>{review.comment}</div>
          
                  {/* Display review.context and review.fixed_context */}
                  <div style={{ fontSize: '12px', marginTop: '8px' }}>
                    {review.context && review.contextFound && (
                      <>
                        {review.fixed_context && (
                          <div style={{ backgroundColor: review.dismissed ? "#d4d4d4" : "#f9f9fb", padding: '8px', borderRadius: '4px' }}>
                            {renderDifferences(review.context, review.fixed_context)}
                          </div>
                        )}
                      </>
                    )}
                  </div>
          
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0', margin: '0' }}>
                    {review.contextFound && (
                    <div style={{ display: 'flex', gap: '12px' }}>
                      {review.fixed_context ?
                      (
                      <Menu>
                        <MenuTrigger disableButtonEnhancement>
                          {(triggerProps: MenuButtonProps) => (
                          <SplitButton icon={<ArrowForward16Filled />} appearance={review.dismissed ? "outline" : "primary"} menuButton={triggerProps} 
                          primaryActionButton={primaryActionButtonProps}
                          style={{ boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'}}>Apply</SplitButton>
                          )}
                        </MenuTrigger>
                        <MenuPopover>
                          <MenuList>
                            <MenuItem onClick={() => applyChanges(review.context, null, review.comment, 'review')}>Apply Comment</MenuItem>
                            <MenuItem onClick={() => applyChanges(review.context, review.fixed_context, null, 'review')}>Apply Redline</MenuItem>
                          </MenuList>
                        </MenuPopover>
                      </Menu>) :
                      (<Button icon={<CommentAdd16Regular />} appearance={review.dismissed ? "outline" : "primary"} onClick={() => applyChanges(review.context, null, review.comment, 'review')} style={{ fontSize: '12px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)' }}>Add Comment</Button>)
                      }
                      <Button icon={<Search16Regular />} appearance={review.dismissed ? "subtle" : "secondary"} onClick={() => navigateToContext(review.context)} style={{ fontSize: '12px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'}}></Button>
                    </div>
                    )}
                    <Button 
                      appearance={review.dismissed ? "secondary" : "outline"}
                      onClick={() => dismissReview(review.context)} 
                      style={{ fontSize: '12px', width: '70px', minWidth: '48px' }}>
                      {review.dismissed ? 'Undismiss' : 'Dismiss'} 
                    </Button>
                  </div>
                </AccordionPanel>
            </AccordionItem>
          );
          
          })}
          </Accordion>
        </Container>
      );
    } else {
      return null;
    }
  };

  const toggleShowGeneratedProofread = () => {
    setShowGeneratedProofread(!showGeneratedProofread);
  };
  

  const onInsight = async () => {
    setError("");
    setLoading(true);
  
    try {
      const text = await Word.run(async (context) => {
        let selectedText = "";
        if (scope === "Selected Section") {
          const selection = context.document.getSelection();
          selection.load("text");
          await context.sync();
          selectedText = selection.text;
        } else {
          const body = context.document.body;
          body.load("text");
          await context.sync();
          selectedText = body.text;
        }

        // Check if there's no text selected when the scope is "Selected Section"
        if (scope === "Selected Section" && !selectedText.trim()) {
          throw new Error("Please select a section of the document.");
        }

        return selectedText;
      });
  
      const data = await callApi('generate-insights', 'POST', { documentText: text, proofreadContext: proofreadContext, party: selectedParty }, setIsAuthenticated);
      
      if (data.success) {
        const insightsWithInitialContextFound = data.insights.map(i => ({ ...i, dismissed: false, contextFound: false }));
        setGeneratedProofread(JSON.stringify(insightsWithInitialContextFound));
        checkContext(JSON.stringify(insightsWithInitialContextFound), setGeneratedProofread);
      } else {
        setError("Error generating insights");
      }

    } catch (error) {
      setError(error.message || "Failed to generate insights");
    } finally {
      setLoading(false);
    }
};
  

  const renderInsightContent = () => {
    if (generatedProofread) {
      const parsedData = parseJSON(generatedProofread);

      if (!parsedData) return <Container><p>Error displaying insights, please try again.</p></Container>;
  
      return (
        <Container>
            <Accordion multiple collapsible>
            {parsedData.sort((a, _) => (a.dismissed ? 1 : -1)).map((insight, index) => {
            const { icon: Icon, color } = iconDetails(insight.type);
            const primaryActionButtonProps = {
              onClick: () => applyChanges(insight.context, insight.fixed_context, insight.comment, 'proofread'),
              style: {
              fontSize: '12px',
              boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)',
              // Add additional styling as needed
              },
              };
            return (
            <AccordionItem 
                key={index} 
                value={String(index)}
                className={`InsightAccordion ${insight.dismissed ? 'dismissed' : ''}`}> 
                  <AccordionHeader expandIconPosition="end" style={{ overflow: "hidden", padding: '16px 10px 12px 10px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon style={{height: "24px", color: color}} aria-hidden="true" />
                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '12px' }}>
                  <div style={{ fontSize: '13px' }}><b>{insight.type}</b></div>
                  <div style={{ fontSize: '12px', color: '#3c3c3c' }}>{insight.title}</div>
                  </div>
                  </div>
                  </AccordionHeader>
                  <AccordionPanel style={{ color: "#3C3C3C", display: 'flex', fontSize: '12px', padding:'0px 20px 16px 20px', flexDirection: 'column', gap:'12px', margin: '0px' }}>
                    <div>{insight.comment}</div>
                    {/* <div>{insight.context}</div> */}

                    {/* Display insight.context and insight.fixed_context */}
                  <div style={{ fontSize: '12px', marginTop: '8px' }}>
                    {insight.context && insight.contextFound && (
                      <>
                        {insight.fixed_context && (
                          <div style={{ backgroundColor: insight.dismissed ? "#d4d4d4" : "#f9f9fb", padding: '8px', borderRadius: '4px' }}>
                            {renderDifferences(insight.context, insight.fixed_context)}
                          </div>
                        )}
                      </>
                    )}
                  </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '0', margin: '0' }}>
                    {insight.contextFound && (
                    <div style={{ display: 'flex', gap: '12px' }}>
                        {insight.fixed_context ?
                        (
                        <Menu>
                          <MenuTrigger disableButtonEnhancement>
                            {(triggerProps: MenuButtonProps) => (
                            <SplitButton icon={<ArrowForward16Filled />} appearance={insight.dismissed ? "outline" : "primary"} menuButton={triggerProps} 
                            primaryActionButton={primaryActionButtonProps} style={{ boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'}}>Apply</SplitButton>
                            )}
                          </MenuTrigger>
                          <MenuPopover>
                            <MenuList>
                              <MenuItem onClick={() => applyChanges(insight.context, null, insight.comment, 'proofread')}>Apply Comment</MenuItem>
                              <MenuItem onClick={() => applyChanges(insight.context, insight.fixed_context, null, 'proofread')}>Apply Redline</MenuItem>
                            </MenuList>
                          </MenuPopover>
                        </Menu>) :
                        (<Button icon={<CommentAdd16Regular />} appearance={insight.dismissed ? "outline" : "primary"} onClick={() => applyChanges(insight.context, null, insight.comment, 'proofread')} style={{ fontSize: '12px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)' }}>Add Comment</Button>)
                      }
                      <Button icon={<Search16Regular />} appearance={insight.dismissed ? "subtle" : "secondary"} onClick={() => navigateToContext(insight.context)} style={{ fontSize: '12px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'}}></Button>
                      </div>
                    )}
                      <Button 
                        appearance={insight.dismissed ? "secondary" : "outline"} 
                        onClick={() => dismissInsight(insight.context)} 
                          style={{ fontSize: '12px', width: '70px', minWidth: '48px' }}>
                          {insight.dismissed ? 'Undismiss' : 'Dismiss'} 
                          </Button>
                    </div>
                  </AccordionPanel>
                </AccordionItem>
            );
            })}
            </Accordion>
          </Container>
      );
    } else {
      return null;
    }
  };

  const insightCount = generatedProofread ? parseJSON(generatedProofread)?.length || 0 : 0;

  const reviewCount = generatedReview ? parseJSON(generatedReview)?.length || 0 : 0;

return (
<>
<div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 120px)' }}>

<Container>
    <div style={{ marginBottom: '8px'}}>
      {loading && <Field validationMessage="Generating text..." validationState="none" validationMessageIcon={<SparkleFilled />}><ProgressBar /></Field>}

      {error && <MessageBar key="error" intent="error">
      <MessageBarBody><MessageBarTitle>Error</MessageBarTitle>{error}</MessageBarBody>
        <MessageBarActions containerAction={ 
        <Button appearance="transparent" icon={<DismissRegular />} onClick={() => setError('')}/>
      }></MessageBarActions></MessageBar>}
    </div>

    {/* Review Button Container */}
    <div style={{ 
    marginTop: '4px', 
    position: 'relative', 
    }}>

  {/* Button Wrapper */}
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column',
    width: generatedReview ? 'calc(100% - 12px)' : 'calc(100%)'
  }} className={`insight-style ${showReviews ? 'open' : ''}`}>
    {/* Review Button */}
    <button onClick={() => setShowReviews(!showReviews)} className={`insightbutton-style ${showReviews ? 'open' : ''}`} style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      width: '100%',
      padding: '10px',
    }}>
      <div style={{ marginRight: '12px', height: '24px', width: '24px' }}>
        <PencilIcon style={{ color: showReviews ? '#625AFA' : '#697387' }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ fontSize: '13px', textAlign: 'left', color: '#000' }}><b>Review</b></div>
        <div style={{ fontSize: '12px', color: showReviews ? '#3c3c3c' : '#697387', textAlign: 'left' }}>Identify key issues and risks in your document</div>
      </div>
    </button>

    {/* Deal Context Section */}
    {showReviews && (
      <>
      <div style={{ padding: '12px' }}>
        {/* Scope Selection Dropdown */}
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '4px' }}>Scope</label>
          <select
            value={scope}
            onChange={(e) => setScope(e.target.value)}
            style={{ color: "#242424", background: '#F5F5F5', fontSize: '12px', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
          >
            <option value="Whole Document">Whole Document</option>
            <option value="Selected Section">Selected Section</option>
          </select>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '4px' }}>Background (Optional)</label>
          <textarea
            value={reviewContext}
            rows={2}
            placeholder="Describe the context behind the document."
            onChange={(e) => setReviewContext(e.target.value)}
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #ccc', background: '#FaFaFa' }}
          ></textarea>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            appearance="primary"
            disabled={loading}
            style={{ fontSize: '12px', padding: '4px 8px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)', borderRadius: '4px' }}
            onClick={onReview}>
            Generate <span style={{ marginLeft: '4px' }}>→</span>
          </Button>
        </div>
      </div>
      </>
    )}
  </div>

  {/* ChevronRightIcon positioned outside */}
  {generatedReview && (
    <div style={{
      position: 'absolute',
      right: '-12px', // Position it outside the parent div
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    }} onClick={toggleShowGeneratedReview}>
      <ChevronRightIcon style={{ height: '24px', width: '24px', color: '#625AFA' }} />
    </div>
  )}
</div>

  {/* Proofread Insights Button */}
  <div style={{ 
    position: 'relative', 
    marginTop: '4px',
  }}>

  {/* Button and Context Section Wrapper */}
  <div style={{ 
    display: 'flex', 
    flexDirection: 'column', 
    width: generatedProofread ? 'calc(100% - 12px)' : 'calc(100%)'
  }} className={`insight-style ${showInsights ? 'open' : ''}`}>
    {/* Proofread Button */}
    <button onClick={() => setShowInsights(!showInsights)} className={`insightbutton-style ${showInsights ? 'open' : ''}`} style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textAlign: 'left',
      width: '100%',
      padding: '10px',
    }}>
      <div style={{ height: '24px', width: '24px', marginRight: '12px' }}>
      <BoltIcon style={{ height: '24px', width: '24px', color: showInsights ? '#625AFA' : '#697387' }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ fontSize: '13px', textAlign: 'left', color: '#000' }}><b>Proofread</b></div>
        <div style={{ fontSize: '12px', color: showInsights ? '#3c3c3c' : '#697387', textAlign: 'left' }}>Identify proofreading mistakes and unusual, missing, or aggressive terms</div>
      </div>
    </button>

    {/* Context Section */}
    {showInsights && (
      <>
      <div style={{ padding: '12px' }}>
        {/* Scope Selection Dropdown */}
          <div style={{ marginBottom: '20px' }}>
            <label style={{ display: 'block', marginBottom: '4px' }}>Scope</label>
            <select
              value={scope}
              onChange={(e) => setScope(e.target.value)}
              style={{ color: "#242424", background: '#F5F5F5', fontSize: '12px', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            >
              <option value="Whole Document">Whole Document</option>
              <option value="Selected Section">Selected Section</option>
            </select>
          </div>
        <div style={{ marginBottom: '20px' }}>
          <label style={{ display: 'block', marginBottom: '4px' }}>Background (Optional)</label>
          <textarea
            value={proofreadContext}
            rows={2}
            placeholder="Describe the context behind the document."
            onChange={(e) => setProofreadContext(e.target.value)}
            style={{ width: '100%', padding: '8px', boxSizing: 'border-box', borderRadius: '4px', border: '1px solid #ccc', background: '#FaFaFa' }}
          ></textarea>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            appearance="primary"
            disabled={loading}
            style={{ fontSize: '12px', boxShadow: '0 1px 2px 0 rgba(0,0,0,0.05)', padding: '4px 8px', borderRadius: '4px' }}
            onClick={onInsight}>
            Scan <span style={{ marginLeft: '4px' }}>→</span>
          </Button>
        </div>
      </div>
      </>
    )}
  </div>

  {/* ChevronRightIcon Positioned Outside */}
  {generatedProofread && (
    <div style={{
      position: 'absolute',
      right: '-12px', // Position outside the parent div
      top: '50%',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
    }} onClick={toggleShowGeneratedProofread}>
      <ChevronRightIcon style={{ height: '24px', width: '24px', color: '#625AFA' }} />
    </div>
  )}
</div>

{/* Custom Playbook Button */}
<div className={`wip-style`}>
  <button className="wipbutton-style" onClick={() => setShowPlaybook(!showPlaybook)} style={{
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    width: '100%',
    padding: '10px', // Added padding for aesthetic spacing
  }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ height: '24px', width: '24px', marginRight: '12px' }}>
        <PuzzlePieceIcon style={{ height: '24px', width: '24px', color: '#697387' }} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ fontSize: '13px', textAlign: 'left' }}><b>Custom Playbook</b></div>
        <div style={{ fontSize: '12px', color: '#697387', textAlign: 'left' }}>Set your own custom instructions</div>
      </div>
    </div>
  </button>
  {/* Playbook Content */}
  {showPlaybook && (
    <div style={{ padding: '12px' }}>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ display: 'block', marginBottom: '4px' }}>Select Playbook</label>
        <select
          value={selectedPlaybook || (playbookSteps[0] && playbookSteps[0].name)}
          onChange={(e) => setSelectedPlaybook(e.target.value)}
          style={{ color: "#242424", background: '#FFFFFF', fontSize: '12px', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        >
          {playbookSteps.map((playbook, index) => (
            <option key={index} value={playbook.name}>{playbook.name}</option>
          ))}
        </select>
      </div>
      {selectedPlaybook && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Button onClick={() => editPlaybook(selectedPlaybook)} icon={<PencilSquareIcon />}>Edit</Button>
          <Button onClick={() => deletePlaybook(selectedPlaybook)} icon={<TrashIcon />}>Delete</Button>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => runPlaybook(userEmail, setIsAuthenticated)}>Run Playbook</Button>
      </div>
    </div>
  )}

  {/* Add Playbook Button */}
  {!showAddPlaybook && showPlaybook && (
    <div style={{ padding: '12px' }}>
      <Button appearance="primary" onClick={handleShowAddPlaybook}>Add Playbook</Button>
    </div>
  )}
  {/* Playbook Creation Section */}
  {showAddPlaybook && showPlaybook && (
    <div style={{ padding: '12px' }}>
      <div style={{ marginBottom: '10px' }}>
        <label style={{ display: 'block', marginBottom: '4px' }}>Playbook Name</label>
        <Input
          type="text"
          value={playbookName}
          onChange={(e) => setPlaybookName(e.target.value)}
          disabled={isEditingPlaybook}
        />
      </div>
      <div style={{ marginBottom: '20px' }}>
        {playbookSteps.map((step, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Input
              type="text"
              value={step}
              onChange={(e) => {
                const updatedSteps = [...playbookSteps];
                updatedSteps[index] = e.target.value;
                setPlaybookSteps(updatedSteps);
              }}
              style={{ flexGrow: 1, marginRight: '8px' }}
            />
            <Button onClick={() => removeStep(index)} icon={<TrashIcon />} />
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
        <div>
            <label style={{ display: 'block', marginBottom: '4px' }}>New Step</label>
            <Input
              type="text"
              value={newStep}
              onChange={(e) => setNewStep(e.target.value)}
            />
        </div>
            <Button onClick={addStep}>Add Step</Button>
            
      </div>
      <div style={{ marginBottom: '' }}>
        <Button onClick={() => savePlaybook(userEmail, setIsAuthenticated)}>Save Playbook</Button>
      </div>
    </div>
  )}
</div>

</Container>
</div>

{showGeneratedReview && (
  <div
    style={{
      position: 'fixed',
      top: '40px', 
      left: 0,
      width: '100vw',
      height: 'calc(100vh - 40px)', // Maintain full height minus the top gap
      borderRadius: '16px 16px 0px 0px', 
      background: '#FFFFFF', 
      boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
  {/* Fixed header bar */}
  <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 24px',
        borderBottom: '1px solid #E1E1E1', // A subtle border for separation
      }}
    >
      {/* Header Title and Insight Count */}
      <div style={{ display: "flex", alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Review</span>
        <div style={{
          color:'#2C671E',
          backgroundColor:'#DDF6C7',
          borderRadius:'5px',
          padding: '5px',
          marginLeft: '10px',
          minWidth: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <span style={{ fontWeight: 'bold' }}>{reviewCount}</span>
        </div>
      </div>
      
      {/* Exit button */}
      <div
        onClick={() => setShowGeneratedReview(false)}
        style={{
          cursor: 'pointer',
        }}
      >
        <ChevronDownIcon style={{ height: '16px', width: '16px', color: '#000' }} />
      </div>
    </div>

    {/* Scrollable content area */}
    <div style={{
      overflowY: 'auto',
      height: '100%',
    }}>
      {renderReviewContent()}
    </div>
  </div>
)}

{showGeneratedProofread && (
  <div
    style={{
      position: 'fixed',
      top: '40px', 
      left: 0,
      width: '100vw',
      height: 'calc(100vh - 40px)', // Maintain full height minus the top gap
      borderRadius: '16px 16px 0px 0px', 
      background: '#FFFFFF', 
      boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      zIndex: 1000,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
  {/* Fixed header bar */}
  <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 24px',
        borderBottom: '1px solid #E1E1E1', // A subtle border for separation
      }}
    >
      {/* Header Title and Insight Count */}
      <div style={{ display: "flex", alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Insights</span>
        <div style={{
          color:'#2C671E',
          backgroundColor:'#DDF6C7',
          borderRadius:'5px',
          padding: '5px',
          marginLeft: '10px',
          minWidth: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <span style={{ fontWeight: 'bold' }}>{insightCount}</span>
        </div>
      </div>
      
      {/* Exit button */}
      <div
        onClick={() => setShowGeneratedProofread(false)}
        style={{
          cursor: 'pointer',
        }}
      >
        <ChevronDownIcon style={{ height: '16px', width: '16px', color: '#000' }} />
      </div>
    </div>

    {/* Scrollable content area */}
    <div style={{
      overflowY: 'auto',
      height: '100%',
    }}>
      {renderInsightContent()}
    </div>
  </div>
)}

{/* Generated Playbook Results */}
{showGeneratedPlaybook && (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '12px 24px',
      borderBottom: '1px solid #E1E1E1', // A subtle border for separation
    }}
  >
    {/* Fixed header bar */}
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 24px',
        borderBottom: '1px solid #E1E1E1', // A subtle border for separation
      }}
    >
       <div style={{ display: "flex", alignItems: 'center' }}>
        <span style={{ fontWeight: 'bold', fontSize: '16px' }}>Playbook Results</span>
        <div style={{
          color:'#2C671E',
          backgroundColor:'#DDF6C7',
          borderRadius:'5px',
          padding: '5px',
          marginLeft: '10px',
          minWidth: '18px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
          <span style={{ fontWeight: 'bold' }}>X/Y</span>
        </div>
      </div>
      {/* Exit button */}
      <div
        onClick={() => setShowGeneratedProofread(false)}
        style={{
          cursor: 'pointer',
        }}
      >
        <ChevronDownIcon style={{ height: '16px', width: '16px', color: '#000' }} />
      </div>
    </div>
    <div style={{ overflowY: 'auto', height: '100%' }}>
      {Array.isArray(playbookResults) && playbookResults.length > 0 ? (
        playbookResults.map((result, index) => (
          <div key={index} style={{ marginBottom: '1rem', borderBottom: '1px solid #ccc', paddingBottom: '1rem' }}>
            <div><strong>Comment:</strong> {result.comment}</div>
            <div><strong>Context:</strong> {result.context}</div>
            {result.fixed_context && <div><strong>Fixed Context:</strong> {result.fixed_context}</div>}
            <Button onClick={() => applyChanges(result.context, result.fixed_context, result.comment, 'playbook')}>Apply Change</Button>
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>

  </div>
)};
</>
);
};

export default InsightGenerator;